import React, { useState } from "react";
import axios from "axios";

function App() {
    const [prompt, setPrompt] = useState("");
    const [charLimit, setCharLimit] = useState(200);
    const [responses, setResponses] = useState({});
    const [loading, setLoading] = useState(false);

    // Backend API URL (works locally & on Render)
    const API_URL = process.env.REACT_APP_BACKEND_URL || "";

    const fetchResponses = async () => {
        if (!prompt) {
            alert("Please enter a prompt.");
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(`${API_URL}/query`, {
                prompt: `${prompt} Limit response to ${charLimit} characters.`,
            });

            setResponses(response.data);
        } catch (error) {
            console.error("Error fetching responses:", error);
            alert("Failed to fetch responses. Check server.");
        }

        setLoading(false);
    };

    return (
        <div style={{ textAlign: "center", fontFamily: "Arial, sans-serif" }}>
            <h1>Compare search:</h1>
            <input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Enter your prompt..."
                style={{ padding: "10px", width: "60%", marginBottom: "10px" }}
            />
            <div style={{ margin: "10px" }}>
                <label>Character Limit: {charLimit}</label>
                <input
                    type="range"
                    min="100"
                    max="1000"
                    step="50"
                    value={charLimit}
                    onChange={(e) => setCharLimit(Number(e.target.value))}
                />
            </div>
            <button
                onClick={fetchResponses}
                style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                }}
            >
                {loading ? "Fetching..." : "Get Responses"}
            </button>

            {Object.keys(responses).length > 0 && (
                <div style={{ textAlign: "left", marginTop: "20px", width: "60%", margin: "auto" }}>
                    <h2>Results:</h2>
                    {Object.entries(responses).map(([model, response]) => (
                        <p key={model}>
                            <strong>{model}:</strong> {response}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
}

export default App;